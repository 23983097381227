// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require rails-ujs
//= require jquery
//= require best_in_place
//= require jquery_ujs
//= require jquery-ui
//= require jquery-fileupload
//= require jquery.purr
//= require best_in_place.purr
//= require best_in_place.jquery-ui
//= require bootstrap-datepicker
//= require jquery.inputmask.bundle.min
//= require chartkick
//= require dropzone
//= require Chart.bundle
//= require activestorage
//= require_tree .

require("regenerator-runtime/runtime");
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("chartkick")
require("chart.js")
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('../vendor/best_in_place.js');
require('../vendor/jquery.purr.js');
require('../vendor/youtube.js');
require('../vendor/modal.js');
require('../vendor/filterrific-jquery.js');
require('/public/bs460/jquery.min.js');

require('jquery-mask-plugin');
//= require jquery.inputmask.bundle.min

require('inputmask');
// require('../vendor/latexit.js');
// require('../vendor/table_editor_controller.js');
require("controllers")

$.jMaskGlobals.watchDataMask = true;

import '../stylesheets/rails_admin'

import jquery from 'jquery';
window.$ = window.jquery = jquery;

window.Rails = Rails

import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "chartkick/chart.js"

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $(".best_in_place").best_in_place();
});


$(document).ready(function() {
  /* Activating Best In Place */
  jQuery(".best_in_place").best_in_place();
  // jQuery(".best_in_place.purr").best_in_place_purr();
});

$(document).ready(function(){
  $('#date_input').datepicker();
  $('#date_input1').datepicker();
  $('#date_input2').datepicker();
  $('#date_input3').datepicker();
  $('#date_input4').datepicker();
});

$.datepicker.setDefaults({
  dateFormat: 'dd-mm-yyyy'
});

$( ".selector" ).datepicker({
  dateFormat: "dd-mm-yyyy"
});

$(document).on('turbolinks:load', function () {
  var im = new Inputmask('999.999.999-99');
  var selector = $('#person_document');
  im.mask(selector);
});


require("trix")
require("@rails/actiontext")

